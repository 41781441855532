<template>
  <el-config-provider :locale="store.getters.language === 'en' ? en : zhCn">
    <router-view />
  </el-config-provider>
</template>

<script setup>
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import en from 'element-plus/lib/locale/lang/en'
import { useStore } from 'vuex'
import { generateNewStyle, writeNewStyle } from '@/utils/theme'
import { watchSwitchLang } from '@/utils/i18n'
import { refreshToken } from './api/sys'
import { dataColorPaletteTask } from 'echarts/lib/visual/style'
// refreshToken().then((data) => {
//   console.log('refresh', data)
// })

const store = useStore()
generateNewStyle(store.getters.mainColor).then((newStyleText) => {
  writeNewStyle(newStyleText)
})
/**
 * 监听 语言变化，重新获取个人信息
 */
watchSwitchLang(() => {
  if (store.getters.token) {
    store.dispatch('user/getUserInfo')
  }
})
</script>

<style lang="scss">
.text-gray-600 {
  --un-text-opacity: 1;
  color: rgba(75, 85, 99, var(--un-text-opacity));
}
.items-center {
  align-items: center;
}
.inline-flex {
  display: inline-flex;
}
.w-35 {
  width: 8.75rem;
}
.ml-3 {
  margin-left: 0.75rem;
}
.w-50 {
  width: 12.5rem !important;
}
.m-2,
[m~='\32 '] {
  margin: 0.5rem;
}
</style>
