import request from '@/utils/request'

/**
 * 登录
 */
export const login = (data) => {
  return request({
    url: '/sys/login',
    method: 'POST',
    data
  })
}
/**
 * 刷新token
 */
export const refreshToken = (token) => {
  return request({
    url: '/authorizations/current',
    method: 'PUT'
  })
}

/**
 * 获取用户信息
 */
export const getUserInfo = () => {
  return request({
    url: '/sys/profile'
  })
}
/**
 * 获取客服qq
 *
 */
export const getKefuQQ = () => {
  return request({
    url: '/sys/qq'
  })
}
/**
 * 获取系统设置
 */
export const getSysInfo = (name) => {
  return request({
    url: 'sys/value',
    params: {
      name
    }
  })
}
