import layout from '@/layout'

export default {
  path: '/finance',
  component: layout,
  redirect: '/finance/czjl',
  name: 'financeManage',
  meta: { title: 'financeManage', icon: 'finance' },
  children: [
    {
      path: '/finance/czjl',
      component: () =>
        import(/* webpackChunkName: "bills" */ '@/views/adv/bills'),
      meta: {
        title: 'czjl',
        icon: 'adv'
      }
    },
    // {
    //   path: '/finance/xfjl',
    //   component: () =>
    //     import(/* webpackChunkName: "xfjl" */ '@/views/finance/xfjl'),
    //   meta: {
    //     title: 'xfjl',
    //     icon: 'finance'
    //   }
    // },
    {
      path: '/finance/bb',
      component: () =>
        import(/* webpackChunkName: "xfjl" */ '@/views/finance/bb'),
      meta: {
        title: 'bb',
        icon: 'finance'
      }
    }
  ]
}
