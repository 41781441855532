import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import installElementPlus from './plugins/element'
// 初始化样式表
import '@/styles/index.scss'
//导入svgIcon
import installIcons from '@/icons'
// 导入路由鉴权
import './permission'
// i18n （PS：导入放到 APP.vue 导入之前，因为后面我们会在 app.vue 中使用国际化内容）
import i18n from '@/i18n'
// filter
import installFilter from '@/filters'
import installDirective from '@/directives'
import installBaseComponent from '@/registeBaseComponent'

const app = createApp(App)
installElementPlus(app)
installIcons(app)
installFilter(app)
installDirective(app)
installBaseComponent(app)
app.use(store).use(router).use(i18n).mount('#app')
