import layout from '@/layout'

export default {
  path: '/adv',
  component: layout,
  redirect: '/adv/index',
  name: 'advManage',
  meta: { title: 'adv', icon: 'adv' },
  children: [
    {
      path: '/adv/index',
      component: () =>
        import(/* webpackChunkName: "adv" */ '@/views/adv/index'),
      meta: {
        title: 'adv',
        icon: 'adv'
      }
    },
    {
      path: '/adv/bangmai',
      component: () =>
        import(/* webpackChunkName: "bangmai" */ '@/views/adv/bangmai'),
      meta: {
        title: 'bangmai',
        icon: 'adv'
      }
    }
    // {
    //   path: '/adv/fuwus',
    //   component: () =>
    //     import(/* webpackChunkName: "fuwus" */ '@/views/adv/fuwus'),
    //   meta: {
    //     title: 'fuwus',
    //     icon: 'adv'
    //   }
    // },
    // {
    //   path: '/adv/bills',
    //   component: () =>
    //     import(/* webpackChunkName: "bills" */ '@/views/adv/bills'),
    //   meta: {
    //     title: 'advbill',
    //     icon: 'adv'
    //   }
    // }
  ]
}
